<template>
	<v-card
		:is="nuxtLink"
		class="product-card rounded-10 gap-20 p-10 text-gray-700"
		:to="localePath(`/${product.uri}`)">
		<template #image>
			<v-image
				v-if="img"
				:alt="img.alt"
				class="bg-white border border-gray-300 rounded-5"
				:height="img.height"
				ratio="1:1"
				:src="img.url"
				:width="img.width" />

			<div
				v-if="labels.length"
				class="bg-gray-100 rounded-5 px-10 py-5 absolute left-10 top-10 text-brand">
				<v-label
					v-for="(label, i) in labels"
					:key="i"
					:class="{
						'mt-5': i > 0,
					}"
					:color="label.color"
					:value="label.value" />
			</div>

			<div
				v-if="price"
				class="bg-gray-100 rounded-5 px-10 py-5 absolute right-10 top-10">
				<span class="block font-bold lh-none text-brand text-xs uppercase">
					{{ product.variants.length > 1 ? `From: ${price}` : price }}
				</span>
			</div>
		</template>

		<template #body>
			<v-title
				class="product-card__title h4"
				:tag="headingTag ?? 'h2'"
				:value="product.title" />

			<v-subtitle
				class="product-card__subtitle mt-5"
				:value="product.subtitle" />

			<v-copy class="mt-10 text-sm" :value="product.description" />
		</template>

		<template #footer>
			<v-button
				is="span"
				class="product-card__btn block"
				color="primary"
				fill="outline"
				shape="round"
				size="sm"
				value="View Product" />
		</template>
	</v-card>
</template>

<script lang="ts" setup>
	import type { Product } from '@/graphql/product'
	import { formatCurrency } from '@/utilities/format-currency'
	import { getThumbnail } from '@/utilities/get-media-thumbnails'
	import { isBefore, subDays } from 'date-fns'

	const props = defineProps<{
		product: Product
		headingTag?: string
		thumbnailSizes?: string | Record<string, any>
	}>()

	const localePath = useLocalePath()

	const nuxtLink = resolveComponent('NuxtLink')

	const img = computed(() => getThumbnail(props.product.images))

	const variants = computed(() => props.product.variants ?? [])

	const labels = computed(() => {
		const values = []

		const isNew = isBefore(
			subDays(new Date(), 60),
			new Date(props.product.dateCreated),
		)

		const isOnSale = variants.value.some((v) => v.salePrice !== v.price)

		const isAvailable = variants.value.some((v) => v.isAvailable)

		if (isNew) values.push({ color: 'brand', value: 'New' })
		if (isOnSale) values.push({ color: 'success', value: 'Sale' })
		if (!isAvailable) values.push({ color: 'danger', value: 'Sold Out' })

		return values
	})

	const price = computed(() => {
		const prices = variants.value.map((v) => v.price)
		const min = Math.min(...prices)

		return min && isFinite(min) ? formatCurrency(min) : null
	})
</script>

<style lang="scss" scoped>
	.product-card {
		background-color: var(--card-bg-color, #{$gray-100});
		transition: all 200ms ease;

		&:hover {
			transform: scale(1.02);
		}

		&__title {
			color: var(--card-title-color, #{$gray-700});
		}

		&__subtitle {
			color: var(--card-subtitle-color, #{$gray-500});
		}
	}
</style>
